<template>
  <div>
    <div class="main-title">企业列表</div>
    <div class="content-list-page page-content">
      <div class="list-head-box"></div>

      <div class="padding-t-sm padding-b-sm">
        <span class="margin-l-sm fl-l margin-t-sm"> </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-input
            v-model="searchData.name"
            clearable
            placeholder="请输入企业名称"
            size="mini"
          />
        </span>
        <span class="margin-l-sm fl-l margin-t-sm">
          <el-select
            v-model="searchData.status"
            clearable
            placeholder="请选择状态"
            size="mini"
          >
            <el-option :value="0" label="停用"> </el-option>
            <el-option :value="1" label="启用"> </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="fl-r">
          <el-button
            size="small"
            type="primary"
            @click="$router.push('enterprise/add')"
            >新增</el-button
          >
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          highlight-current-row
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80" />

          <el-table-column label="企业名称" property="name" />
          <el-table-column label="是否默认" property="address">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.is_default"
                :active-value="1"
                :inactive-value="0"
                @change="changeIsDefault(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>

          <el-table-column label="状态" property="address">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="changeStatus(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>

          <el-table-column label="创建时间" property="created_at" />
          <el-table-column label="更新时间" property="updated_at" />

          <el-table-column label="操作" property="address">
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="$router.push('/enterprise/details/' + scope.row.id)"
                >详情
              </el-link>
              -
              <el-link
                type="primary"
                @click="$router.push('/enterprise/edit/' + scope.row.id)"
                >编辑
              </el-link>
              -
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="enterpriseDel(scope.row.id)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "@/js/config";

export default {
  name: "List",
  data() {
    return {
      searchData: {},
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      imageUrl: config.imageUrl,
    };
  },
  methods: {
    ...mapActions("enterprise", [
      "enterpriseList",
      "setEnterpriseStatus",
      "setEnterpriseDefault",
      "delEnterprise",
    ]),
    async changeIsDefault(row) {
      try {
        let form = {
          id: row.id,
          is_default: row.is_default,
        };
        const { res_info } = await this.setEnterpriseDefault(form);
        if (res_info !== "ok") return;
        this.$message.success("设置成功");
        await this.getList();
      } catch (e) {
        console.log(e);
      }
    },
    async changeStatus(row) {
      try {
        let form = {
          id: row.id,
          status: row.status,
        };
        const { res_info } = await this.setEnterpriseStatus(form);
        if (res_info !== "ok") return;
        this.$message.success("设置成功");
      } catch (e) {
        console.log(e);
      }
    },
    search() {
      this.page = 1;
      this.getList();
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
    },
    async enterpriseDel(id) {
      try {
        const { res_info } = await this.delEnterprise({ id });
        if (res_info !== "ok") return;
        this.$message.success("删除成功");
        await this.getList();
      } catch (e) {
        console.log(e);
      }
    },
    setPage(page) {
      this.page = page;
    },
    async getList() {
      try {
        this.loading = true;
        let form = {
          page: this.page,
          pageSize: this.pageSize,
          ...this.searchData,
        };
        const { data } = await this.enterpriseList(form);
        this.tableData = data.list;
        this.total = data.total;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>